import PhotoSwipeLightbox from 'photoswipe/lightbox';
const lightbox = new PhotoSwipeLightbox({
  gallery: '#gallery--webp-demo',
  children: 'a',
  pswpModule: () => import('photoswipe'),
});

// Parse data-pswp-webp-src attribute
lightbox.addFilter('itemData', (itemData, index) => {
  const webpSrc = itemData.element.dataset.pswpWebpSrc;
  if (webpSrc) {
    itemData.webpSrc = webpSrc;
  }
  return itemData;
});

// use <picture> instead of <img>
lightbox.on('contentLoad', (e) => {
    const { content, isLazy } = e;

    if (content.data.webpSrc) {
      // prevent to stop the default behavior
      e.preventDefault();

      content.pictureElement = document.createElement('picture');
      
      const sourceWebp = document.createElement('source');
      sourceWebp.srcset = content.data.webpSrc;
      sourceWebp.type = 'image/webp';

      const sourcePng = document.createElement('source');
      sourcePng.srcset = content.data.src;
      sourcePng.type = 'image/png';

      content.element = document.createElement('img');
      content.element.src = content.data.src;
      content.element.setAttribute('alt', '');
      content.element.className = 'pswp__img';

      content.pictureElement.appendChild(sourceWebp);
      content.pictureElement.appendChild(sourcePng);
      content.pictureElement.appendChild(content.element);

      content.state = 'loading';

      if (content.element.complete) {
        content.onLoaded();
      } else {
        content.element.onload = () => {
          content.onLoaded();
        };

        content.element.onerror = () => {
          content.onError();
        };
      }
    }
});


// by default PhotoSwipe appends <img>,
// but we want to append <picture>
lightbox.on('contentAppend', (e) => {
  const { content } = e;
  if (content.pictureElement && !content.pictureElement.parentNode) {
    e.preventDefault();
    content.slide.container.appendChild(content.pictureElement);
  }
});

lightbox.on('contentRemove', (e) => {
  const { content } = e;
  if (content.pictureElement && content.pictureElement.parentNode) {
    content.pictureElement.remove();
  }
});

lightbox.init();